<template>
	<div class="container">
		<div class="main">
			<div class="table-box">
			  <h4>中学生影视推荐</h4>
			</div>
			<div class="content" v-if="content" v-html="content"></div>
			<el-empty v-else description="暂无数据"></el-empty>
			<div></div>
		</div>
	</div>
</template>

<script>
import { getVideoList } from "@/api/public/readingOrPsy"
export default {
	data() {
		return {
			queryParams: {
				pageSize: 1
			},
			content: '',
			mainList: {
				list: [],
				total: 0,
				loading: false
			}
		}
	},
	created() {
		this.getMainList()
	},
	methods: {
		// 查询主列表
		getMainList() {
			getVideoList({}).then(res => {
				if (res.data.length > 0) {
					this.content = res.data[0].content
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.content img {
	max-width: 100%;
}
</style>